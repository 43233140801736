import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import AppBar from "../components/AppBar";
import Toolbar from "../components/Toolbar";
import {Link as RouterLink} from "react-router-dom";
import {Typography, useMediaQuery} from "@mui/material";

const rightLink = {
    fontSize: 16,
    color: "common.white",
    ml: 3,
};

function AppAppBar() {

    const isMobile = useMediaQuery("(max-width:768px)");


    return (
        <div>
            <AppBar position="fixed">
                <Toolbar sx={{justifyContent: "center"}}>
                    <Box sx={{flex: 1}}
                    component={RouterLink}
                    to="/"
                    />
                    <Link
                        variant="h6"
                        underline="none"
                        color="inherit"
                        component={RouterLink}
                        to="/"
                        sx={{fontSize: 24, textTransform: 'none' }}
                    >
                    <img
                        style={{marginTop: 8}}
                        width={200}
                        src={"/logo_renovations_7.png"}
                        alt={'logo'}
                    />
                    </Link>
                    <Link
                        variant="h6"
                        underline="none"
                        color="inherit"
                        component={RouterLink}
                        to="/"
                        sx={{fontSize: 32, textTransform: 'none' }}
                    >

                        {/*{"TerNova"}*/}
                    </Link>
                    <Box sx={{flex: 1, display: "flex", justifyContent: "flex-end"}}>
                        {isMobile ? "" : <><a style={{textDecoration: 'none', color: 'inherit'}}
                                              href={`tel:${'+48 572 383 559'}`}>+48 572 383 559</a></>}
                    </Box>
                </Toolbar>
            </AppBar>
            <Toolbar/>
        </div>
    );
}

export default AppAppBar;
