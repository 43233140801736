import * as React from "react";
import Button from "../components/Button";
import Typography from "../components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";
import { Link as RouterLink } from "react-router-dom";

const backgroundImage =
  "man_with_paint_roller.jpg";

export default function MainBanner() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: "#7fc7d9", // Average color of the background image.
        backgroundPosition: "center",
      }}
    >
      <img
        style={{ display: "none" }}
        src={backgroundImage}
        alt="increase priority"
      />
      <Typography color="inherit" align="center" variant="h2" marked="center"
                  data-aos="zoom-in"
      >
        Tworzymy Przestrzeń Marzeń
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { sx: 4, sm: 10 } }}
        data-aos="zoom-in" data-aos-delay={500}
      >
        Twój Dom, Nasza Pasja: Kompleksowe Usługi Remontowe i Aranżacyjne
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        href={`tel:${'+48 572 383 559'}`}
        to="/sign-up/"
        sx={{ minWidth: 200 }}
        data-aos="zoom-in" data-aos-delay={1000}
      >
        <a style={{textDecoration: 'none', color: 'inherit'}} href={`tel:${'+48 572 383 559'}`}>+48 572 383 559</a>
      </Button>
      <Typography variant="body2" color="inherit" sx={{ mt: 2 }}  data-aos="zoom-in" data-aos-delay={1500}>
        Zadzwoń do nas
      </Typography>
    </ProductHeroLayout>
  );
}
