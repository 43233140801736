import * as React from "react";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "../components/Button";
import Typography from "../components/Typography";
import { Link as RouterLink } from "react-router-dom";

const item: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

const number = {
  fontSize: 24,
  fontFamily: "default",
  color: "secondary.main",
  fontWeight: "medium",
};

const image = {
  height: 55,
  my: 4,
};

function HowToOrderServices() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", bgcolor: "secondary.light", overflow: "hidden" }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src="/productCurvyLines.png"
          alt="curvy lines"
          sx={{
            pointerEvents: "none",
            position: "absolute",
            top: -180,
            opacity: 0.7,
          }}
        />
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 14 }} data-aos="zoom-in">
          Jak skorzystać z naszych usług
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <Box sx={item} data-aos="zoom-in" data-aos-delay={100}>
                <Box sx={number}>1.</Box>
                <Box
                  component="img"
                  src="/icons/phone-line-icon.svg"
                  alt="suitcase"
                  sx={image}
                />
                <Typography variant="h5" align="center" >
                  Zadzwoń do nas na numer <a style={{textDecoration: 'none', color: 'inherit'}} href={`tel:${'+48 572 383 559'}`}>+48 572 383 559</a> i opowiedz nam, czego dokładnie potrzebujesz. Jesteśmy gotowi wysłuchać Twoich potrzeb i odpowiedzieć na Twoje pytania.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item} data-aos="zoom-in" data-aos-delay={200}>
                <Box sx={number}>2.</Box>
                <Box
                  component="img"
                  src="/icons/partner-handshake-icon.svg"
                  alt="graph"
                  sx={image}
                />
                <Typography variant="h5" align="center">
                  Jeśli potrzebujesz, nasi doświadczeni pracownicy są gotowi przyjechać i ocenić, co dokładnie trzeba zrobić. To pierwszy krok do rozpoczęcia Twojego projektu remontowego. Możemy również udzielić odpowiedzi na wiele pytań podczas pierwszej rozmowy telefonicznej.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item} data-aos="zoom-in" data-aos-delay={300}>
                <Box sx={number}>3.</Box>
                <Box
                  component="img"
                  src="/icons/paste-icon.svg"
                  alt="clock"
                  sx={image}
                />
                <Typography variant="h5" align="center" >
                  Po rozmowie lub wizycie naszego pracownika, będziemy pracować nad wyceną Twojego projektu. Niezwłocznie skontaktujemy się z Tobą, aby przedstawić Ci naszą propozycję cenową. Dzięki temu będziesz miał pełen obraz kosztów przed rozpoczęciem prac remontowych.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        <Button
          color="secondary"
          size="large"
          variant="contained"
          href={`tel:${'+48 572 383 559'}`}
          to="/sign-up/"
          sx={{ mt: 8 }}
        >
          +48 572 383 559
        </Button>
      </Container>
    </Box>
  );
}

export default HowToOrderServices;
