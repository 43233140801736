import * as React from "react";
import ServiceCategories from "./modules/views/ServiceCategories";
import NeedHelp from "./modules/views/NeedHelp";
import AppFooter from "./modules/views/AppFooter";
import MainBanner from "./modules/views/MainBanner";
import ShortDescription from "./modules/views/ShortDescription";
import HowOrderServices from "./modules/views/HowToOrderServices";
import Contact from "./modules/views/Contact";
import AppAppBar from "./modules/views/AppAppBar";
import withRoot from "./modules/withRoot";
import WorkGallery from "./modules/views/WorkGallery"
import AboutUs from "./modules/views/AboutUs";

function Index() {
    return (
        <React.Fragment>
            <AppAppBar/>
            <MainBanner/>
            <AboutUs/>
            <ShortDescription/>
            <ServiceCategories/>
            <HowOrderServices/>
            <WorkGallery/>
            <Contact/>
            <NeedHelp/>
            <AppFooter/>
        </React.Fragment>
    );
}

export default withRoot(Index);
