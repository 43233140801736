import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";

const ImageBackdrop = styled("div")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: "#000",
  opacity: 0.5,
  transition: theme.transitions.create("opacity"),
}));

const ImageIconButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  display: "block",
  padding: 0,
  borderRadius: 0,
  height: "40vh",
  [theme.breakpoints.down("md")]: {
    width: "100% !important",
    height: 300,
  },
  "&:hover": {
    zIndex: 1,
  },
  "&:hover .imageBackdrop": {
    opacity: 0.15,
  },
  "&:hover .imageMarked": {
    opacity: 0,
  },
  "&:hover .imageTitle": {
    border: "4px solid currentColor",
  },
  "& .imageTitle": {
    position: "relative",
    padding: `${theme.spacing(2)} ${theme.spacing(4)} 14px`,
  },
  "& .imageMarked": {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
}));

const images = [
  {
    url: "activities/painting.jpg",
    title: "Malowanie",
    width: "40%",
  },
  {
    url: "activities/ceramic_tiles.jpg",
    title: "Płytki",
    width: "20%",
  },
  {
    url: "activities/floors.jpg",
    title: "Podłogi",
    width: "40%",
  },
  {
    url: "activities/hydraulic.jpg",
    title: "Hydraulika",
    width: "38%",
  },
  {
    url: "activities/finishing_coat.jpg",
    title: "Gładzie",
    width: "38%",
  },
  {
    url: "activities/decorations.jpg",
    title: "Tynki dekoracyjne",
    width: "24%",
  },
  {
    url: "activities/regips.jpg",
    title: "Regipsy",
    width: "40%",
  },
  {
    url: "activities/hang_ceiling.jpg",
    title: "Sufity podwieszane",
    width: "20%",
  },
  {
    url: "activities/electricity.jpg",
    title: "Instalacje elektryczne",
    width: "40%",
  },
];

export default function ServiceCategories() {
  return (
    <Container component="section" sx={{ mt: 8, mb: 4 }}>
      <Typography variant="h4" marked="center" align="center" component="h2">
        Nasze Usługi Remontowe Obejmują:
      </Typography>
      <Box sx={{ mt: 8, display: "flex", flexWrap: "wrap" }} >
        {images.map((image, index) => (
          <ImageIconButton
              data-aos="flip-left"
              data-aos-delay={index * 100}

              key={image.title}
            style={{
              width: image.width,
              border: "2px solid white",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundSize: "cover",
                backgroundPosition: "center 40%",
                backgroundImage: `url(${image.url})`,


              }}
            />
            <ImageBackdrop className="imageBackdrop" />
            <Box
              sx={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "common.white",
              }}
            >
              <Typography
                component="h3"
                variant="h6"
                color="inherit"
                className="imageTitle"
              >
                {image.title}
                <div className="imageMarked" />
              </Typography>
            </Box>
          </ImageIconButton>
        ))}
      </Box>
    </Container>
  );
}
