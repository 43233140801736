import * as React from "react";
import {Theme} from "@mui/material/styles";
import {SxProps} from "@mui/system";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';

const item: SxProps<Theme> = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    px: 5,
};

const number = {
    fontSize: 24,
    fontFamily: "default",
    color: "secondary.main",
    fontWeight: "medium",
};

const image = {
    height: 55,
    my: 4,
};

function WhyTernova() {
    return (
        <Box
            component="section"
            sx={{display: "flex", bgcolor: "secondary.light", overflow: "hidden"}}
        >
            <Container
                sx={{
                    mt: 10,
                    mb: 15,
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Box
                    component="img"
                    src="/productCurvyLines.png"
                    alt="curvy lines"
                    sx={{
                        pointerEvents: "none",
                        position: "absolute",
                        top: -180,
                        opacity: 0.7,
                    }}
                />
                <Typography variant="h4" marked="center" component="h2" sx={{mb: 14}} data-aos="zoom-in">
                    Dlaczego Wybrać TerNova?
                </Typography>
                <div>
                    <Grid container spacing={5}>
                        <Grid item xs={12} md={4}>
                            <Box sx={item} data-aos="zoom-in" data-aos-delay={100}>
                                <Box
                                    component="img"
                                    src="/icons/setting-line-icon.svg"
                                    alt="suitcase"
                                    sx={image}
                                />
                                {/*<AutoFixHighOutlinedIcon sx={{ fontSize: 100 }}/>*/}
                                <br/>
                                <Typography variant="h5" align="center" >
                                    <b>Indywidualne podejście</b><br/><br/>
                                    każdy projekt traktujemy indywidualnie, dostosowując się do specyficznych potrzeb
                                    klienta.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box sx={item} data-aos="zoom-in" data-aos-delay={200}>
                                <Box
                                    component="img"
                                    src="/icons/distribution-icon.svg"
                                    alt="graph"
                                    sx={image}
                                />
                                <Typography variant="h5" align="center">
                                    <b>Transparentność</b><br/><br/>
                                    zapewniamy pełną przejrzystość kosztów i terminów realizacji.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box sx={item} data-aos="zoom-in" data-aos-delay={300}>
                                <Box
                                    component="img"
                                    src="/icons/excellence-honor-icon.svg"
                                    alt="clock"
                                    sx={image}
                                />
                                <Typography variant="h5" align="center">
                                    <b>Jakość i Gwarancja</b><br/><br/>
                                    stosujemy tylko materiały najwyższej jakości i oferujemy gwarancję na wykonane prace.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </div>

            </Container>
        </Box>
    );
}

export default WhyTernova;
