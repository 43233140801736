import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import {Link as RouterLink} from "react-router-dom";

function Copyright() {
    return (
        <React.Fragment>
            {"© "}
            <Typography variant="caption">
                {"Site made by "}
                <Link
                    href="https://www.codeworld.pl"
                    rel="created"
                    title="codeworld.pl"
                >
                    Code World {new Date().getFullYear()}
                </Link>
            </Typography>

        </React.Fragment>
    );
}

const iconStyle = {
    width: 48,
    height: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "primary.light",
    mr: 1,
    "&:hover": {
        bgcolor: "secondary.main",
    },
};


export default function AppFooter() {
    return (<>
            <Typography
                component="footer"
                sx={{display: "flex", bgcolor: "secondary.light"}}
            >
                <Container sx={{my: 8, display: "flex"}}>
                    <Grid container spacing={5}>
                        <Grid item xs={6} sm={4} md={4}>
                            <Typography variant="h6" marked="left" gutterBottom>
                                Social media
                            </Typography>
                            <Box component="ul" sx={{m: 0, listStyle: "none", p: 0}}>
                                <Grid container>
                                    <Grid item>
                                        <Box component="a" href="https://m.facebook.com/groups/389011080320648/?ref=share" sx={iconStyle}>

                                            <img src="/appFooterFacebook.png" alt="Facebook"/>
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <Box
                                            component="a"
                                            href="https://www.instagram.com/ternova.remonty?igsh=bWlnazJiYnN1Nm8y"
                                            sx={iconStyle}
                                        >
                                            <img src="/appFooterTwitter.png" alt="Twitter"/>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={4} md={4}>
                            <Typography variant="h6" marked="left" gutterBottom>
                                Informacje
                            </Typography>
                            <Box component="ul" sx={{m: 0, listStyle: "none", p: 0}}>
                                <Box component="li" sx={{py: 0.5}}>
                                    <Link component={RouterLink} to="/terms/">
                                        Polityka prywatności
                                    </Link>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={8} md={4}>
                            <Typography variant="h6" marked="left" gutterBottom>
                                Kontakt
                            </Typography>
                            TerNova<br/>
                            Andrii Ternovyi<br/>
                            <a style={{textDecoration: 'none', color: 'inherit'}} href={`tel:${'+48 572 383 559'}`}>+48 572 383 559</a><br/>
                            <a style={{textDecoration: 'none', color: 'inherit'}} href={`mailto:${'biuro@ternova.pl'}`}>biuro@ternova.pl</a><br/>
                            NIP 5272689047<br/>
                        </Grid>
                    </Grid>
                </Container>
            </Typography>
            <Box display="flex" justifyContent="center" style={{width: '100%'}}>
                <Typography variant="caption">
                    {"Site made by "}
                    <Link
                        href="https://www.codeworld.pl"
                        rel="created"
                        title="codeworld.pl"
                    >
                        Code World
                    </Link>
                </Typography>
            </Box>
            <br/>
        </>
    );
}
