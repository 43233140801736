import React from "react";
import "./App.css";
import Home from "./onepirate/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Privacy from "./onepirate/Privacy";
import SignIn from "./onepirate/SignIn";
import SignUp from "./onepirate/SignUp";
import ForgotPassword from "./onepirate/ForgotPassword";
import Terms from "./onepirate/Terms";
import 'react-image-gallery/styles/css/image-gallery.css';
import CookieConsent from "react-cookie-consent";
import Link from "@mui/material/Link";
import AOS from 'aos';
import 'aos/dist/aos.css';


function App() {

  AOS.init({
    once: true,
    delay: 50,
    duration: 500,
    easing: 'ease-in-out',
  });


  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route path="/forgot-password">
            <ForgotPassword />
          </Route>
          <Route path="/sign-up">
            <SignUp />
          </Route>
          <Route path="/sign-in">
            <SignIn />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
      <CookieConsent
          location="bottom"
          buttonText="Rozumiem"
          cookieName="cookie"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
      >
        Ta strona korzysta z ciasteczek aby świadczyć usługi na najwyższym poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich użycie.
        <Link> Polityka prywatności</Link>
      </CookieConsent>

    </React.Fragment>
  );
}

export default App;
