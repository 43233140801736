import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";


function AboutUs() {
  return (
    <Box
      component="section"
    >
      <Container
        sx={{
          mt: 10,
          mb: 10,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 5 }} >
          O nas
        </Typography>
        <div>
          <Typography variant="h5" align="left">
          Witamy w TerNova - Twoim zaufanym partnerze w dziedzinie remontów i renowacji!

          Jesteśmy zespołem doświadczonych specjalistów w branży budowlanej, oferującymi kompleksowe usługi remontowe i
            renowacyjne dla domów, mieszkań oraz obiektów komercyjnych. Nasza firma TerNova założona została z pasji do
            tworzenia i odnawiania, co odzwierciedla się w każdym z naszych projektów.
            <br/>
            <br/>
            <br/>
            <b>Misja</b><br/><br/>

            Naszą misją jest dostarczanie usług najwyższej jakości, z uwzględnieniem indywidualnych potrzeb i oczekiwań
            każdego klienta. Wierzymy, że każdy remont to nie tylko praca, ale przede wszystkim szansa na stworzenie czegoś wyjątkowego i funkcjonalnego.

            <br/>
            <br/>
            <br/>
            <b>Doświadczenie i Profesjonalizm</b><br/><br/>

            W TerNova skupiamy się na ciągłym rozwoju i ulepszaniu naszych umiejętności. Nasi pracownicy to wykwalifikowani fachowcy,
            którzy regularnie uczestniczą w szkoleniach branżowych, aby być na bieżąco z najnowszymi trendami i technologiami w budownictwie.

          </Typography>
        </div>
      </Container>
    </Box>
  );
}

export default AboutUs;
