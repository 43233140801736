import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import Snackbar from "../components/Snackbar";

function Contact() {
    const [open, setOpen] = React.useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container component="section" sx={{mt: 10, display: "flex"}}
        >
            <Grid container>
                <Grid item xs={12} md={6} sx={{zIndex: 1}}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            bgcolor: "secondary.main",
                            py: 8,
                            px: 3,
                        }}
                        data-aos="fade-right"

                    >
                        <Box
                            component="form"
                            onSubmit={handleSubmit}
                            sx={{maxWidth: 400}}
                        >
                            <Typography variant="h2" component="h2" gutterBottom>
                                Kontakt
                            </Typography>
                            <Typography variant="h5">
                                TerNova<br/>
                                Andrii Ternovyi<br/>
                                ul. Paderewskiego 3b/22<br/>
                                87-162 Lubicz Górny (k/Torunia)
                            </Typography>
                            <Typography variant="h5">
                                <a style={{textDecoration: 'none', color: 'inherit'}} href={`tel:${'+48 572 383 559'}`}>+48 572 383 559</a><br/>
                                biuro@ternova.pl
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{display: {md: "block", xs: "none"}, position: "relative"}}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            top: -67,
                            left: -67,
                            right: 0,
                            bottom: 0,
                            width: "100%",
                            background: "url(/productCTAImageDots.png)",
                        }}

                    />
                    <Box
                        component="img"
                        src="three_workers_front.jpg"
                        alt="call to action"
                        data-aos="fade-left"
                        sx={{
                            position: "absolute",
                            top: -28,
                            left: -28,
                            right: 0,
                            bottom: 0,
                            width: "100%",
                            maxHeight: 360,
                            maxWidth: 500,
                            objectFit: "cover", // Ustawia sposób dopasowania obrazka w kontenerze
                            objectPosition: "top"
                        }}
                    />
                </Grid>
            </Grid>
            <Snackbar
                open={open}
                closeFunc={handleClose}
                message="We will send you our best offers, once a week."
            />
        </Container>
    );
}

export default Contact;
