import ImageGallery from "react-image-gallery";

import * as React from "react";
import Typography from "../components/Typography";

const images = [
    {
        original: "/gallery/fb_img_1705684571528.jpg",
        thumbnail: "/gallery/fb_img_1705684571528_thumbnail.jpg",
    },
    {
        original: "/gallery/fb_img_1705684574617.jpg",
        thumbnail: "/gallery/fb_img_1705684574617_thumbnail.jpg",
    },
    {
        original: "/gallery/fb_img_1705684576878.jpg",
        thumbnail: "/gallery/fb_img_1705684576878_thumbnail.jpg",
    },
    {
        original: "/gallery/fb_img_1705684579013.jpg",
        thumbnail: "/gallery/fb_img_1705684579013_thumbnail.jpg",
    },
    {
        original: "/gallery/fb_img_1705684581047.jpg",
        thumbnail: "/gallery/fb_img_1705684581047_thumbnail.jpg",
    },
    {
        original: "/gallery/fb_img_1705684583172.jpg",
        thumbnail: "/gallery/fb_img_1705684583172_thumbnail.jpg",
    },
];

class WorkGallery extends React.Component {
    render() {
        return <div style={{marginTop: 50, marginBottom: 150}}>
            <Typography variant="h4" marked="center" align="center" component="h2" style={{marginBottom: 50}}>
                Nasze realizacje
            </Typography>
            <ImageGallery items={images} />

        </div>;
    }
}
export default WorkGallery